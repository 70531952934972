import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <title>Privacy policy</title>
      <meta name="author" content="eo" />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            ' * {margin:0; padding:0; text-indent:0; }\n h2 { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 10pt; }\n .p, p { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; margin:0pt; }\n .a { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; }\n .s1 { color: #0562C1; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 12pt; }\n .s2 { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; }\n li {display: block; }\n #l1 {padding-left: 0pt;counter-reset: c1 1; }\n #l1> li>*:first-child:before {counter-increment: c1; content: counter(c1, lower-roman)". "; color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; }\n #l1> li:first-child>*:first-child:before {counter-increment: c1 0;  }\n li {display: block; }\n #l2 {padding-left: 0pt;counter-reset: d1 1; }\n #l2> li>*:first-child:before {counter-increment: d1; content: counter(d1, decimal)". "; color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; }\n #l2> li:first-child>*:first-child:before {counter-increment: d1 0;  }\n #l3 {padding-left: 0pt;counter-reset: d2 1; }\n #l3> li>*:first-child:before {counter-increment: d2; content: counter(d2, lower-roman)". "; color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; }\n #l3> li:first-child>*:first-child:before {counter-increment: d2 0;  }\n',
        }}
      />
      <h2
        style={{
          paddingTop: "3pt",
          paddingLeft: "182pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        ДЕКЛАРАЦИЯ ЗА ПОВЕРИТЕЛНОСТ
      </h2>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        по чл. 6, ал. 1, буква а), б) и в) и чл.13 от Регламент (ЕС) 2016/679 на
        Европейския парламент и на съвета от 27.04.2016 г. относно защита на
        личните данни
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{ paddingLeft: "182pt", textIndent: "0pt", textAlign: "center" }}
      >
        ДЕКЛАРИРАМ:
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <ol id="l1">
        <li data-list-text="i.">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-24pt",
              textAlign: "justify",
            }}
          >
            Давам изричното си съгласие администраторът на лични данни{" "}
            <b>„ДЦ ЕО Дент“ ООД, БУЛСТАТ: 113515453 </b>със седалище и адрес на
            управление: гр.Перник ул.
          </p>
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            „Черешово топче“ №2, да събира, съхранява и обработва (записва,
            организира, структурира, адаптира, извлича, консултира, употребява,
            разкрива чрез предаване, разпространяване или друг начин, по който
            данните стават достъпни, подрежда или комбинира, ограничава, изтрива
            или унищожава) личните ми данни за целите и при условията по-долу.
          </p>
        </li>
        <li data-list-text="ii.">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-27pt",
              textAlign: "justify",
            }}
          >
            При предоставяне на съгласието ми за обработване на лични данни съм
            уведомен за следното: Администраторът на данни е{" "}
            <b>„ДЦ ЕО Дент“ ООД, БУЛСТАТ: 113515453 </b>със седалище и адрес на
            управление: гр.Перник ул. „Черешово топче“
          </p>
        </li>
      </ol>
      <p
        style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "justify" }}
      >
        №2, представлявано от Венета Павлова. Предоставянето на личните ми данни
        и личните данни на лицето, чийто родител и законен представител съм на
        Дружеството за посочените по-долу цели е <b>доброволно</b>. В случай на
        отказ да предоставя личните си данни, Дружеството няма да може да ми
        предостави исканата услуга. Обработването е необходимо за спазване на
        законови задължения, възложени на администратора на данни, както и за
        защита на правата на администратора по съдебен ред. Личните данни се
        събират, обработват и съхраняват с цел изграждане и функциониране на
        самрт-приложение , съобразно вътрешните правила и регулации на
        Дружеството, в това число за следните цели:
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <ol id="l2">
        <li data-list-text={1}>
          <p
            style={{
              paddingLeft: "17pt",
              textIndent: "-12pt",
              textAlign: "left",
            }}
          >
            Валидиране на данните, въведени от субекта, както и неговата
            идентификация
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text={2}>
          <p
            style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
          >
            Създаване на профил на потребителя-родител, чрез който се извършва
            проследяване на режима на поддържане на оралната хигиена на детето
            му.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text={3}>
          <p
            style={{
              paddingLeft: "17pt",
              textIndent: "-12pt",
              textAlign: "left",
            }}
          >
            Последваща обработка - корекция или изтриване на данните, в
            определените срокове
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <ol id="l3">
            <li data-list-text="i.">
              <p
                style={{
                  paddingLeft: "41pt",
                  textIndent: "-24pt",
                  textAlign: "justify",
                }}
              >
                Информиран/а съм своевременно и изчерпателно от{" "}
                <b>„ДЦ ЕО Дент“ ООД, БУЛСТАТ: 113515453 </b>за логиката на всяко
                автоматизирано обработване на личните ми данни.
              </p>
            </li>
            <li data-list-text="ii.">
              <p
                style={{
                  paddingLeft: "41pt",
                  textIndent: "-27pt",
                  textAlign: "justify",
                }}
              >
                Запознат/а съм, че няма да бъдат обработвани специални категории
                данни (генетични данни, данни за здравословно състояние и др.).
              </p>
            </li>
            <li data-list-text="iii.">
              <p
                style={{
                  paddingLeft: "41pt",
                  textIndent: "-31pt",
                  textAlign: "justify",
                }}
              >
                Съгласен/съгласна съм личните ми данни да бъдат обработвани от
                дружества от групата на.{" "}
                <b>„ДЦ ЕО Дент“ ООД, БУЛСТАТ: 113515453</b>, за целите на
                директния маркетинг – за анкети и допитвания относно
                удовлетвореността на клиента, за предлагане на услуги и продукти
                на Дружеството по и-мейл, телефон, поща, за статистически цели
                на администратора, за планиране и отчетност.
              </p>
            </li>
            <li data-list-text="iv.">
              <p
                style={{
                  paddingLeft: "41pt",
                  textIndent: "-30pt",
                  textAlign: "justify",
                }}
              >
                Съгласен/съгласна съм Дружеството да записва провеждани с мен
                телефонни разговори. Информиран/а съм, че имам право на достъп
                до личните си данни, да искам корекция, копиране или изтриване
                на личните данни или ограничаване на обработването им. Освен
                това имам право да възразя срещу обработването на данните (право
                на забрава), както и право на преносимост на данните. Тези права
                мога да упражня чрез подаване на заявление за достъп, промяна,
                заличаване,
              </p>
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "41pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                <a
                  href="mailto:zubcheto.eo@gmail.com"
                  className="a"
                  target="_blank"
                >
                  изпратено до e-mail:{" "}
                </a>
                zubcheto.eo@gmail.com или по пощата на адреса на Дружеството.
              </p>
            </li>
            <li data-list-text="v.">
              <p
                style={{
                  paddingLeft: "41pt",
                  textIndent: "-27pt",
                  textAlign: "justify",
                }}
              >
                <a href="mailto:kzld@cpdp.bg" className="a" target="_blank">
                  Информиран/а съм, че в случай че обработването на личните
                  данни се основава единствено на моето съгласие, имам право да
                  направя писмено оттегляне на съгласието по всяко време, но
                  това не влияе върху законосъобразността на обработването въз
                  основа на съгласието, дадено преди то да бъде
                  оттеглено.Информиран/а съм, че в случай че смятам, че личните
                  данни се обработват неправомерно и това би довело до
                  накърняване на правата и интересите ми мога да се свържа с
                  длъжностното лице по защита на личните данни, както и да подам
                  жалба до Комисията за защита на личните данни – гр. София,
                  бул. „Проф. Цветан Лазаров” 2,{" "}
                </a>
                <a href="mailto:kzld@cpdp.bg" className="s1" target="_blank">
                  kzld@cpdp.bg
                </a>
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        С тикчета се маркира:
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        Декларирам, че доброволно и безусловно се съгласявам с гореописаната
        декларация да предоставя личните си данни.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        <span className="p">
          Декларирам, че съм родител и законен представител на свото дете, като
          доброволно и безусловно се съгласявам с гореописаната декларация да
          предоставя личните{" "}
        </span>
        <span className="s2">му/й</span>
        <span className="p"> данни.</span>
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        Декларирам, че в случай, че подавам информация от трето лице без да имам
        неговото изрично съгласие и не съм упълномощен изрично, автоматично се
        превръщам в администратор на лични данни, както и нося пълната
        отговорност за това.
      </p>
    </>
  );
};

export default PrivacyPolicy;
